<template>
    <div class="app-header">
        <img
            class="app-logo fade-left"
            src="@/assets/images/header/logo-1.png"
            @click="$router.push('/')"
        />

        <div class="nav-list fade-right">
            <div class="nav-item active">展会概况</div>
            <div class="nav-item" @click="jumpSite(surveySite)">展览展示</div>
            <div class="nav-item" @click="jumpSite(cloudSite)">企业名录</div>
            <div class="nav-item" @click="jumpSite(contactSite)">联系方式</div>
            <div class="nav-item" @click="jumpSite(site)">返回官网</div>
        </div>
    </div>
</template>
<script>
import { jump } from "@/utils/utils.js";
export default {
    data() {
        return {
            site: "https://www.cimee.com.cn/index.aspx",
            surveySite: "https://www.cimee.com.cn/survey.aspx",
            cloudSite: "https://www.cimee.com.cn/cloud.aspx?type=150",
            contactSite: "https://www.cimee.com.cn/contact.aspx",
        };
    },
    methods: {
        jumpSite(url, target = "_self") {
            jump(url, target);
        },
    },
};
</script>
<style lang="less" scoped>
.app-header {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;

    width: 100%;
    height: 80px;
    padding: 0px 301px 0 40px;
    display: flex;
    align-items: center;
    transition: all 0.5s;

    &:hover {
        background-color: #012f6d;
    }

    .app-logo {
        width: 359px;
        cursor: pointer;
    }

    .nav-list {
        margin-left: auto;
        display: flex;

        .nav-item {
            font-size: 16px;
            color: #fff;
            margin-right: 82px;
            cursor: pointer;

            &:last-of-type {
                margin-right: 0;
            }

            &.active,
            &:hover {
                color: #46c0ff;
            }
        }
    }
}
</style>
