<template>
    <div class="app-page">
        <Header></Header>
        <router-view></router-view>
    </div>
</template>
<script>
import Header from "./components/Header";
export default {
    data() {
        return {};
    },
    computed: {},
    methods: {},
    watch: {},
    components: {
        Header,
    },
};
</script>
<style lang="less" scoped>
.app-page {
    width: 100%;
    height: 100%;
}
</style>
