<template>
    <router-view></router-view>
</template>
<script>
import "lib-flexible/flexible.js";
export default {
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
</script>
<style lang="less" scoped></style>
